import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
};

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card style={{width:"90%"}}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.item.imageUrl}
          title={props.item.title}
        />
        <CardContent onClick={()=>
        {
if(props.item.id && !props.item.viewAble)
{
  document.location.href=document.location.href+"/"+props.item.id;
}

        }}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.item.title}
          </Typography>
          <Typography component="p">
{props.item.description}
</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <div class="fb-share-button" data-href={document.location.href} data-layout="button" data-size="small" data-mobile-iframe="true"><a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?src=sdkpreparse&u="+document.location.href.replace("http://localhost:3000","https://foodequivalents.com")} class="fb-xfbml-parse-ignore">
      <Button>{props.translations.R("SHARE")}</Button>
      </a></div>
        {props.item.id && !props.item.viewAble &&
        <Button size="small" color="primary" onClick={
()=>{

document.location.href=document.location.href+"/"+props.item.id;

}}>
        {props.translations.R("LEARNMORE")}
        </Button>
        }
      </CardActions>
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);