import React, { Component } from 'react';
import './App.css';
import PrimarySearchBar from './PrimarySearchBar';
import TranslationManager from './TranslationManager';
import NetworkManager from './NetworkManager';
import ContentManager from './ContentManager';
import About from './About';
import Home from './Home';
import Swap from './Swap';
import Nutrionists from './Nutrionists';
import Diets from './Diets';
import Diet from './Diet';
import History from './History';
import Recipes from './Recipes';
import Recipe from './Recipe';
import Profile from './Profile';
import { Helmet } from 'react-helmet';
import { Route, Switch } from "react-router-dom";
import Fab from './Fab'


class App extends Component {
  state = {
    contentManager: new ContentManager(this),
    networkManager: new NetworkManager(this),
    translations: new TranslationManager(this)
  };


  renderComponent = (componentNamed, match) => {
    console.log(componentNamed + " " + JSON.stringify(match));
    switch (componentNamed.split("/")[0]) {
      case "about":
        return <About {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
      case "swap":
        return <Swap {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
      case "history":
        return <History {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
      case "profile":
        return <Profile {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
      case "diets":
        return <Diets {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
      case "recipes":
        return <Recipes {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
        case "nutrionists":
        return <Nutrionists {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
        case "diet":
        return <Diet {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
        case "recipe":
        return <Recipe {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;

      case null:
      case "":
      case "home":
        return <Home {...match} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />;
        break;
    }
  }

  isFabEnabled()
  {
    if(this.state.contentManager.displayedContent=="")
    {
      return true;
    }
    return false;
  }

  constructor(props) {
    super(props);
  }

  titleForSection()
  {
    
    var x=this.state.translations.R("title"+this.state.contentManager.currentTitle());
    if(x && x.length>0)
    {
      return x;
    }
    return this.state.contentManager.currentTitle();
    //return this.state.translations.R("title");
  }
  render() {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    var height = y - 64;

    return (
      <div className="App">
        <PrimarySearchBar title={this.titleForSection()} translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />

        <div style={{ overflow: 'auto', overflowY: "scroll", WebkitOverflowScrolling: "touch", height: height }}>
          <Switch>
            <Route history={this.props.history} exact path="/" render={(match) => { return this.renderComponent("home", { match: match }) }} />
            <Route history={this.props.history} exact path="/app" render={(match) => { return this.renderComponent("home", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language" render={(match) => { return this.renderComponent("home", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/swap" render={(match) => { return this.renderComponent("swap", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/about" render={(match) => { return this.renderComponent("about", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/recipes" render={(match) => { return this.renderComponent("recipes", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/recipes/:item" render={(match) => { return this.renderComponent("recipe", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/profile" render={(match) => { return this.renderComponent("profile", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/nutrionists" render={(match) => { return this.renderComponent("nutrionists", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/history" render={(match) => { return this.renderComponent("history", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/diets" render={(match) => { return this.renderComponent("diets", { match: match }) }} />
            <Route history={this.props.history} exact path="/app/:language/diets/:item" render={(match) => { return this.renderComponent("diet", { match: match }) }} />
          </Switch>
        </div>
        {this.isFabEnabled() && <Fab translations={this.state.translations} loginDone={this.loginDone} networkManager={this.state.networkManager} contentManager={this.state.contentManager} />}
      </div>
    );
  }
}

export default App;
/*
Tap here to view components demos: <Button variant="contained" color="primary" onClick={(event) => {document.location.href="https://material-ui.com/demos/cards/";}}>Material-UI website</Button>
*/