var md5 = require('md5');


export default class NetworkManager {


    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    constructor(parent) {
        this.root = parent;
    }


    prepareLogged(command, a = null, b = null, c = null) {
        console.log(command);
        var defaultFe = JSON.parse(localStorage.getItem("FE"));
        if(defaultFe.hasOwnProperty("access_token"))
        {
            // loggedIn
        }
        else
        {
            return null;
        }

        var payload = {
            "device": defaultFe.device,

            "bundleId": defaultFe.bundleId,
            "auth": {
                "provider": defaultFe.provider,
                "access_token": defaultFe.access_token
            },
            "gps": {
                "lat": 0,
                "lon": 0
            },
        };

        var url = null;


        var response = null;
        switch (command) {
            case "sendMessage":
            payload["method"] = "post";
            payload["entity"] = "messages";
            payload["id"] = a;
            payload["data"]= b;
            payload["data"]["l"]=(navigator.language || navigator.userLanguage).substring(0,2);
            url = "/api/5a065e43f60922c0ba30c8e91faed22f/"+ payload["entity"] + "/" + payload["method"] + "/" + payload["id"] ;
            response = {
                url: url,
                payload: payload
            };
            break;
        
            case "get":
                break;
            case "postToGroup":
            payload["method"] = "post";
            payload["entity"] = "groups";
            payload["id"] = b;
            payload["category"] = a;
            payload["data"]= {
                "text": c,
                "l": (navigator.language || navigator.userLanguage).substring(0,2)
              };
            url = "/api/5a065e43f60922c0ba30c8e91faed22f/"+ payload["entity"] + "/" + payload["method"] + "/" + payload["category"] + "/" + payload["id"] ;
            response = {
                url: url,
                payload: payload
            };
            break;
            case "wall":
                payload["method"] = "wall";
                payload["entity"] = "groups";
                payload["id"] = b;
                payload["category"] = a;
                url = "/api/5a065e43f60922c0ba30c8e91faed22f/" + payload["entity"] + "/" + payload["method"] + "/" + payload["category"] + "/" + payload["id"] ;
                response = {
                    url: url,
                    payload: payload,
                    resultsetName: "wall"
                };
                break;
        }


        

        return response;
    }

    prepareDemo(command, a = null, b = null, c = null) {
        console.log(command);
        var defaultFe = JSON.parse(localStorage.getItem("FE"));
        if(defaultFe.hasOwnProperty("access_token"))
        {
            // loggedIn
        }
        else
        {

        }

        var payload = {
            "device": defaultFe.device,

            "bundleId": defaultFe.bundleId,
            "auth": {
                "provider": defaultFe.provider,
                "access_token": defaultFe.access_token
            },
            "gps": {
                "lat": 0,
                "lon": 0
            },
        };

        var url = null;


        var response = null;
        switch (command) {
            case "login":
            {
                var auth = { username: a, password: md5(b), provider: "internal" };
                payload["auth"] = auth;
            }
            payload["method"] = "login";
            payload["entity"] = "login";
            url = "/api/5a065e43f60922c0ba30c8e91faed22f/login/login";
            response = {
                url: url,
                payload: payload
            };
            break;
            case "get":
                break;
            case "wall":
                payload["method"] = "wall";
                payload["entity"] = "groups";
                payload["id"] = b;
                payload["category"] = a;
                url = "/api/5a065e43f60922c0ba30c8e91faed22f/groups/wall";
                response = {
                    url: url,
                    payload: payload,
                    resultsetName: "wall"
                };
                break;
        }


        

        return response;
    }



    prepare(command, a = null, b = null, c = null) {
        console.log(command);
        var defaultFe = JSON.parse(localStorage.getItem("FE"));

        if(defaultFe.hasOwnProperty("access_token") && defaultFe.access_token.length>0)
        {
            return this.prepareLogged(command,a,b,c);
        }
            return this.prepareDemo(command,a,b,c);
    }


    command(command, a = null, b = null, c = null) {
        console.log(command);

        var response = null;
        response = new Promise((resolve, reject) => {

            this.rapi(this.prepare(command, a, b, c)).then((result) => { resolve(result) });
        });

        return response;
    }


    rapi(prepared) {
        return new Promise((resolve, reject) => {
            fetch(prepared.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(prepared.payload)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        resolve(result);
                    },
                    (error) => {
                        alert(error);
                        resolve(null);
                    }
                )
        })
    }
}