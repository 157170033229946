import React, { Component } from 'react';
import MediaCard from './MediaCard';
import { Helmet } from 'react-helmet';

class Recipe extends Component {
    state = {
        language: "en",
        item: {}
    };
    constructor(props) {
        var l = props.translations.language;
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
            l = props.match.match.params.language;
            props.translations.language = l;
        }


        super(props);
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.item) {
            var i = props.match.match.params.item;
            var available = props.translations.R("recipesCards");
            for (var x = 0; x < available.length; x++) {
                if (available[x].id == i) {
                    this.state.item = available[x];
                    this.state.item.viewAble=true;
                    break;
                }
            }
        }

        this.state.language = l;

    }
    render() {
        return (
            <div style={{ margin: "8px" }}>
                <Helmet>
                    <title>{this.state.item.title}</title>
                    <meta property="og:title" content={this.state.item.title} />
                    <meta property="og:description" content={this.state.item.description} />
                    <meta name="description" content={this.state.item.description} />
                    <meta property="og:image" content={this.state.item.imageUrl} />
                </Helmet>
                <center>
                    <MediaCard item={this.state.item} translations={this.props.translations} networkManager={this.props.networkManager} contentManager={this.props.contentManager} />
                </center>
            </div>
        );
    }
}

export default Recipe;
