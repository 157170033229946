import React, { Component } from 'react';
import MediaCard from './MediaCard';

class MediaCards extends Component {
    state = {
        language:"en",
        list:[]
      };
  constructor(props){
    var l=props.translations.language;
    if(props.match && props.match.match && props.match.match.params && props.match.match.params.language)
    {
        l=props.match.match.params.language;
        props.translations.language=l;
    }
    super(props);
    this.state.list=props.list;
    this.state.language=l;
 }
  render() {
    return (
      <div>
          {
              this.state.list.map((item)=>{return <div style={{marginBottom:"8px"}}><MediaCard item={item} translations={this.props.translations} /></div>;})
          }
      </div>
    );
  }
}

export default MediaCards;
