import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add'


class Fab extends Component {
    state = {
        language: "en"
    };
    constructor(props) {
        var l = props.translations.language;
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
            l = props.match.match.params.language;
            props.translations.language = l;
        }
        super(props);

        this.state.language = l;
    }
    render() {
        return (
            <div>
                        <div style={{ padding: "4px", color: "#ffffff", position: "absolute", bottom: "32px", right: "76px", backgroundColor: "#5a5a5a",borderRadius: "4px" }}>
          {Math.floor(Math.random() * 10)} {this.props.translations.R("nutioninistsOnlineNow")}
                </div>
        <Button variant="fab" style={{ position: "absolute", bottom: "16px", right: "16px" }} color="primary" aria-label="Edit"   onClick={(event) => this.props.contentManager.displayContent("swap")}>
          <AddIcon />
        </Button>
            </div>
        );
    }
}

export default Fab;
