import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Router} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import createHistory from "history/createBrowserHistory"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import defaultFeDefault from './defaults';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



if (typeof localStorage.getItem("FE") == 'undefined' || localStorage.getItem("FE")==null)
{
  var defaultFe=defaultFeDefault;
  defaultFe.device=uuidv4();
  localStorage.setItem("FE",JSON.stringify(defaultFe));
  console.log("new session started with device " + defaultFe.device);
  console.log(defaultFe);
}
else
{
  var defaultFe=localStorage.getItem("FE");
  console.log("Restored session using device "+defaultFe.device);
  console.log(localStorage.getItem("FE"));
}



const theme = createMuiTheme({
    palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: '#649632',
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
  });

const history = createHistory()

ReactDOM.render(
<Router history={history} forceRefresh={true}>
<MuiThemeProvider theme={theme}>
<App history={history}/>
</MuiThemeProvider>
</Router>
, document.getElementById('loader'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
