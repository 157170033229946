function initLocation()
{

  var l=document.location.href;
  l=l.split("?")[0];
  var l=l.split("/");
  if(l.length>5)
  {
    l=l[5];
  }
  else
  {
    l="";
  }
  return l;
}

function refreshTitleByContent(displayedContent)
{
  var displayedContentTitle="Food Equivalents";
  if(displayedContent=="home" || displayedContent==null || displayedContent=="" || typeof displayedContent=="undefined")
  {
    
  }
  else {
    var a=displayedContent.split("/");
    var index=0;
    displayedContentTitle=a[index].replace(/(\w)(\w*)/g,function(g0,g1,g2){
      return g1.toUpperCase() + g2.toLowerCase();
    });
}
  return displayedContentTitle;
}



export default class ContentManager
{
  constructor(parent)
  {
    this.root=parent;
  }

  
  root=null;
  displayedContent=initLocation();
  displayedContentTitle=refreshTitleByContent(initLocation());
  logged=0;

  displayContent(contentName)
  {
    if(contentName)this.displayedContent=contentName;
    if(!this.displayedContent || this.displayedContent==""|| this.displayedContent=="home")this.displayedContent="";
    if(this.displayedContent=="home" ||this.displayedContent=="")
    {
        this.root.props.history.push('/app/'+this.root.state.translations.language)
    }
    else
    {
        this.root.props.history.push('/app/'+this.root.state.translations.language+'/'+contentName)
    }
    this.displayedContentTitle=refreshTitleByContent(this.displayedContent);

  }
  currentTitle()
  {
    return this.displayedContentTitle;
  }
  
  isLogged()
  {
    return this.logged;
  }
}