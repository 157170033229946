import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div>
        <center>
        <div style={{width:"90%",padding:"5%",marginTop:"16px",marginBottom:"32px"}}>
          <img src="/logo.svg" width="128" height="128"/>
          <br/>
          <br/>
        {this.props.translations.R("description_"+this.props.contentManager.displayedContent)}
        </div>
          </center>
      </div>
    );
  }
}

export default About;
