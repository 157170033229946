import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import InformationIcon from '@material-ui/icons/InfoOutlined';
import PieChartIcon from '@material-ui/icons/PieChart';
import Divider from '@material-ui/core/Divider';



const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class PrimarySearchAppBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    left:false,

  };
  constructor(props) {
    var l = props.translations.language;
    if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
        l = props.match.match.params.language;
        props.translations.language = l;
    }
super(props);
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };


  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const sideList = (
      <div className={classes.list}>
 <List component="nav">

        <ListItem button onClick={()=>this.props.contentManager.displayContent("home")} >
          <ListItemIcon >
          <SvgIcon  width="64px" height="61px" viewBox="0 0 64 61">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="ui/app_screens/settings/emergency_info" transform="translate(-157.000000, -9.000000)" fill="#669818">
            <g id="gliph" transform="translate(157.000000, 9.000000)">
                <path d="M64,17.072837 L55.9201274,15 L52.5224399,22.5092638 L55.9794949,24.0256086 L57.0167096,21.7334595 C59.0141083,27.1162659 59.1843606,33.0768195 57.4528892,38.8871395 C55.0433523,46.9736673 48.7641314,53.3288859 40.6572735,55.8862382 C35.6291867,57.4721445 30.6060062,57.7146436 25.7270741,56.6060069 C21.2033661,55.5785251 16.9985744,53.3858877 13.568014,50.2652802 L11,53.0023332 C16.8096777,58.286111 24.2330729,61.0429697 31.9690064,60.9994936 C35.2268612,60.9811372 38.5411398,60.4661886 41.8117513,59.4343592 C51.1157727,56.4992493 58.3203422,49.2102636 61.0841217,39.9358762 C63.095749,33.1869586 62.8342375,26.2448145 60.3957528,19.9987689 L63.0457037,20.6784429 L64,17.072837 Z" id="Path"></path>
                <path d="M8.03985639,37.0145894 L6.97702339,39.3475066 C5.10317181,34.5248939 4.73922162,29.2824424 5.9407491,23.9803577 C6.99226998,19.3414542 9.33040408,15.0791071 12.7028452,11.6554752 C16.0806964,8.22607229 20.3457991,5.79120103 25.0382974,4.61296003 C29.7745681,3.42413884 34.4769028,3.41019234 39.0149682,4.5716013 C43.2466269,5.65462137 47.1935191,7.76920337 50.4282493,10.686432 L53,7.9596456 C49.3103321,4.63171573 44.8062027,2.21944749 39.9755016,0.983015715 C34.8059335,-0.339980712 29.4627513,-0.327476868 24.0949779,1.02004612 C18.7208107,2.36949289 13.8365008,5.15735549 9.97075962,9.08210049 C6.11436314,12.9977082 3.43982103,17.8717788 2.23730997,23.177711 C1.37219063,26.9961739 1.22710235,30.8314688 1.80745535,34.5768325 C2.15025705,36.7924067 2.74192205,38.944981 3.57212193,41.0133952 L0.956598771,40.3468474 L0,43.9363949 L8.09936713,46 L11.5052523,38.5241806 L8.03985639,37.0145894 Z" id="Path"></path>
                <path d="M39.6737643,13 L39.3634981,13 C38.6228137,13 38.0539924,13.5526381 38.0539924,14.2930217 L38.0539924,27.2989423 C38.0539924,28.4526689 37.0821293,29.3959388 35.9247148,29.3959388 L35.6129278,29.3959388 C34.4555133,29.3959388 33.4745247,28.4526689 33.4745247,27.2989423 L33.4745247,14.2930217 C33.4745247,13.5541521 32.9163498,13 32.1741445,13 L31.8593156,13 C31.1171103,13 30.5269962,13.5526381 30.5269962,14.2930217 L30.5269962,27.2989423 C30.5269962,28.4526689 29.5779468,29.3959388 28.4190114,29.3959388 L28.1087452,29.3959388 C26.9513308,29.3959388 26.0539924,28.4526689 26.0539924,27.2989423 L26.0539924,14.2930217 C26.0539924,13.5541521 25.4106464,13 24.6684411,13 L24.356654,13 C23.6159696,13 23,13.5526381 23,14.2930217 L23,33.9654229 C23,36.3849178 24.981749,38.298711 27.4121673,38.298711 L27.9992395,38.298711 L26.7581749,56.278399 C26.7262357,57.2670912 27.0988593,58.2027907 27.7908745,58.9128928 C28.4813688,59.6229949 29.4334601,60 30.4281369,60 L33.3543726,60 C34.3460076,60 35.2980989,59.6229949 35.9901141,58.9128928 C36.6821293,58.2012766 37.0532319,57.2549786 37.0243346,56.2662864 L35.78327,38.2971969 L36.6197719,38.2971969 C39.051711,38.2971969 41,36.3818897 41,33.9639088 L41,14.2915076 C40.9969582,13.551124 40.4190114,13 39.6737643,13 Z" id="Path"></path>
            </g>
        </g>
    </g>
    </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("Home")} />
        </ListItem>
        <Divider />
        <ListItem button onClick={()=>this.props.contentManager.displayContent("diets")} >
          <ListItemIcon >
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("Diets")} />
        </ListItem>
        <ListItem button onClick={()=>this.props.contentManager.displayContent("recipes")} >
          <ListItemIcon >
            <PieChartIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("Traditional food")} />
        </ListItem>
        <ListItem button onClick={()=>this.props.contentManager.displayContent("nutrionists")} >
          <ListItemIcon >
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("Find nutritionist")} />
        </ListItem>
        <Divider />
        <ListItem button onClick={()=>this.props.contentManager.displayContent("history")} >
          <ListItemIcon >
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("History")} />
        </ListItem>
        <ListItem button onClick={()=>this.props.contentManager.displayContent("profile")} >
          <ListItemIcon >
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("Profile")} />
        </ListItem>
        <Divider />
        <ListItem button onClick={()=>this.props.contentManager.displayContent("about")} >
          <ListItemIcon >
            <InformationIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.translations.R("About")} />
        </ListItem>
      </List>
      </div>
    );




    return (
      <div className={classes.root}>

        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer"  onClick={this.toggleDrawer('left', true)}>
              <MenuIcon />
            </IconButton>

            <Typography  variant="title" color="inherit">
            {this.props.title}
            </Typography>

       <div className={classes.grow} />
             
              <IconButton color="inherit"  onClick={()=>this.props.contentManager.displayContent("history")} >
                <Badge className={classes.margin} badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : null}
                aria-haspopup="true"
                onClick={()=>this.props.contentManager.displayContent("profile")} 
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

          </Toolbar>
        </AppBar>


   <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrimarySearchAppBar);
