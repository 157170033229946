import React, { Component } from 'react';
import MediaCards from './MediaCards';

class Diets extends Component {
    state = {
        language: "en"
    };
    constructor(props) {
        var l = props.translations.language;
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
            l = props.match.match.params.language;
            props.translations.language = l;
        }
        super(props);

        this.state.language = l;
    }
    render() {
        return (
            <div style={{ margin: "8px" }}>
   <center>
            <MediaCards list={this.props.translations.R("dietsCards")} translations={this.props.translations} networkManager={this.props.networkManager} contentManager={this.props.contentManager} />
          </center>
          <div style={{width:"90%",padding:"5%",marginTop:"16px",marginBottom:"32px"}}>
        {this.props.translations.R("description_"+this.props.contentManager.displayedContent)}
        </div>

                      </div>
        );
    }
}

export default Diets;
