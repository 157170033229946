import TranslationData from './translations';
function initLanguage()
{
  var browserLanguage=(navigator.language || navigator.userLanguage).substring(0,2);


  var l=document.location.href;
  l=l.split("?")[0];
  l=l.split("/");
  if(l.length>4 && l[3]=="app" && l[4].length==2)browserLanguage=l[4];
  return browserLanguage;

}
export default class TranslationManager
{
  constructor(parent)
  {
    this.root=parent;
  }
  language=initLanguage();
  R(key)
  {
    if(TranslationData[this.language] && TranslationData[this.language][key])return TranslationData[this.language][key];
    else if(TranslationData["en"][key])return TranslationData["en"][key];
    return null;
  }
}
