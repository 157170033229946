import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class Nutrionist extends Component {
    state = {
        language: "en"
    };
    constructor(props) {
        var l = props.translations.language;
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
            l = props.match.match.params.language;
            props.translations.language = l;
        }
        super(props);

        this.state.language = l;
    }
    render() {
        return (
            <div style={{ margin: "8px" }}>
                <center>
                </center>
                <div style={{width:"90%",padding:"5%",marginTop:"16px",marginBottom:"32px"}}>
        {this.props.translations.R("description_"+this.props.contentManager.displayedContent)}
        </div>
            </div>
        );
    }
}

export default Nutrionist;
