/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Creatable from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const suggestions = [
    { label: 'chicken' },
    { label: 'potato' },
    { label: 'carrots' },
    { label: 'vegetables' },
    { label: 'milk' },
    { label: 'proteins' },

].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
}));

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class IntegrationReactSelect extends React.Component {
    state = {
        single: null,
        multi: null,
        step: 0,
        notes:""
    };

    handleChange = name => value => {
        this.setState({
            [name]: value,
        });
    };
    handleChange2 = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };
    render() {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <div className={classes.root}>
                <br />
                <Grid container>
                    <Grid item xs={2}>
                    <center>
                        <div style={{ textAlign: "center", margin: "4px", fontSize: "24px", backgroundColor: "#649632", borderRadius: "32px", padding: "4px", color: "#ffffff", width: "32px", height: "32px", lineHeight: "32px" }}>
                            1
</div>
<div style={{width:"2px",height:"200px",backgroundColor:"#888888"}}></div>
<div style={{ textAlign: "center", margin: "4px", fontSize: "24px", backgroundColor: "#649632", borderRadius: "32px", padding: "4px", color: "#ffffff", width: "32px", height: "32px", lineHeight: "32px" }}>
                            2
</div>
<div style={{width:"2px",height:"200px",backgroundColor:"#888888"}}></div>
<div style={{ textAlign: "center", margin: "4px", fontSize: "24px", backgroundColor: "#649632", borderRadius: "32px", padding: "4px", color: "#ffffff", width: "32px", height: "32px", lineHeight: "32px" }}>
                            3
</div>
</center>
                    </Grid>
                    <Grid item xs={10}>
                    <div style={{margin:"12px"}}></div>
                    <div style={{height:"248px"}}>
                    
                        <strong>Add any food you want to replace</strong>

                            <Creatable
                                classes={classes}
                                styles={selectStyles}
                                textFieldProps={{
                                    label: '',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                options={suggestions}
                                components={components}
                                value={this.state.multi}
                                onChange={this.handleChange('multi')}
                                placeholder="80 gr roasted chicken"
                                isMulti
                            />
                            <br />
                            <br />
                            <Creatable
                                classes={classes}
                                styles={selectStyles}
                                textFieldProps={{
                                    label: 'is there something that you want to avoid?',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                options={suggestions}
                                components={components}
                                value={this.state.multi}
                                onChange={this.handleChange('multi')}
                                placeholder="milk"
                                isMulti
                            />
                                            
                                            <br />
                            <br />

                        <center>
                        <Button variant="contained" color="primary" onClick={(event) => { this.state.step = 1; }}>Continue</Button>
                        </center>
                        </div>
                        <div style={{height:"248px"}}>
                        <strong>Add or shoot alternatives</strong>
                        <Creatable
                                classes={classes}
                                styles={selectStyles}
                                textFieldProps={{
                                    label: 'which food is required for your diet?',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                options={suggestions}
                                components={components}
                                value={this.state.multi}
                                onChange={this.handleChange('multi')}
                                placeholder="protein"
                                isMulti
                            />
                        <br />
                            Menu pictures
                            <br />

                        <div style={{margin:"8px",width:"64px",height:"96px",backgroundColor:"#efefef",float:"left"}}>
                        <Button style={{marginTop:"24px",marginLeft:"4px"}} variant="fab" color="primary" aria-label="Edit">
          <AddIcon />
        </Button>
                        </div>
                        <div style={{margin:"8px",width:"64px",height:"96px",backgroundColor:"#efefef",float:"left"}}>
                        <Button style={{marginTop:"24px",marginLeft:"4px"}} variant="fab" color="primary" aria-label="Edit">
          <AddIcon />
        </Button>
                        </div>
                        <div style={{margin:"8px",width:"64px",height:"96px",backgroundColor:"#efefef",float:"left"}}>
                        <Button style={{marginTop:"24px",marginLeft:"4px"}} variant="fab" color="primary" aria-label="Edit">
          <AddIcon />
        </Button>
                        </div>

                        <div style={{clear:"both"}}></div>
                        </div>
                        <div>
                        <strong>Add something else and submit</strong>
                        <center>
                        <TextField style={{width:"90%"}}
          id="outlined-multiline-flexible"
          label="Add some usefull notes to the nutritionist"
          multiline
          rowsMax="4"
          value={this.state.notes}
          onChange={this.handleChange2('notes')}
          className={classes.textField}
          margin="normal"
          helperText="hello"
          variant="outlined"
        />
                        <br />
                        <Button variant="contained" color="primary" onClick={(event) => { 


var userId="nutrionist";
var complexData={
"text":this.state.notes,
"images":[],
"toReplace":[],
"toAvoid":[],
"toHave":[]
};
this.props.networkManager.command("sendMessage",userId,complexData).then((response)=>{

    if(response)
    {
      if(response.hasOwnProperty("data"))
      {
        alert("Successfully queued your request");

      }
      else
      {
        alert("Error");

      }
    }
    else
    {
        alert("Error");
    }
});



                        }}>Ask substitution</Button>
                        </center>
                        </div>
                    </Grid>

                    </Grid>


            </div>
        );
    }
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);