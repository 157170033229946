import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
class Profile extends Component {
    state = {
        language: "en",
        username:"",
        password:""
    };
    constructor(props) {
        var l = props.translations.language;
        if (props.match && props.match.match && props.match.match.params && props.match.match.params.language) {
            l = props.match.match.params.language;
            props.translations.language = l;
        }


        super(props);

        var defaultFe = JSON.parse(localStorage.getItem("FE"));
        console.log(defaultFe);
        this.state.username=defaultFe.username;
        this.state.password=defaultFe.password;
        this.state.language = l;
    }
    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };
    render() {
        return (
<div style={{ margin: "8px" }}>
<TextField
          id="filled-name"
          label="Email"
          type="email"
          autoComplete="email"
          value={this.state.username}
          onChange={this.handleChange('username')}
          margin="normal"
          variant="filled"
        />

<br />
<TextField
          id="filled-name"
          label="Password"
          type="password"
          value={this.state.password}
          onChange={this.handleChange('password')}
          margin="normal"
          variant="filled"
        />

<br />
<FormControlLabel
          control={
            <Checkbox
            />
          }
          label="I'm nutritionist"
        />
<br />

<Button variant="contained" color="primary" onClick={(event) => { 

this.props.networkManager.command("login",this.state.username,this.state.password).then((response)=>{

    if(response)
    {
      if(response.hasOwnProperty("data") && response.data.hasOwnProperty("access_token") && response.data.access_token.length>0)
      {
        var currentFe=JSON.parse(localStorage.getItem("FE"));
        currentFe["username"]=this.state.username;
        currentFe["password"]=this.state.password;
        currentFe["access_token"]=response.data.access_token;
        currentFe["provider"]=response.data.provider;
        localStorage.setItem("FE",JSON.stringify(currentFe));
        alert("Successfully logged in");
      }
      else
      {
        alert("Food equivalents cloud can not identify your credentials. Please review your username and password and try again.");
      }

    }
    else
    {
      alert("I can not connect to Food equivalents cloud, may be you are experiencing connectivity issue. Please review your internet connectivity and try again later.");
    }



  });


}}>Login</Button>    
<br />
<div style={{width:"90%",padding:"5%",marginTop:"16px",marginBottom:"32px"}}>
        {this.props.translations.R("description_"+this.props.contentManager.displayedContent)}
        </div>

                
            </div>
        );
    }
}

export default Profile;
